<template>
  <div class="modal-card" style="width: auto; min-width: 300px">
    <header class="modal-card-head">
      <p class="modal-card-title">Ordenes seleccionadas</p>
      <button
          type="button"
          class="delete"
          @click="$parent.close()"/>
    </header>
    <section class="modal-card-body">
      <div class="card-content">
        <b-table
            style=""
            :data="data"
            striped
            bordered
            height="100%"
            narrowed
            sticky-header
            :selected.sync="selected"
            @dblclick="onSelectPatient">
          <b-table-column label="Fecha" field="date" v-slot="props">
            {{ props.row.date_time_formatted }}
          </b-table-column>
          <b-table-column label="Orden" field="no" v-slot="props">
            {{ props.row.no }}
          </b-table-column>
          <b-table-column label="Sucursal" field="branch_data" v-slot="props">
            {{ props.row.branch_data.name }}
          </b-table-column>
          <b-table-column label="Ubicacion" field="location" v-slot="props">
            {{ props.row.location }}
          </b-table-column>
          <b-table-column label="Local" field="no_local" v-slot="props">
            {{ props.row.no_local }}
          </b-table-column>
          <b-table-column label="Pase" field="pass" v-slot="props">
            {{ props.row.pass }}
          </b-table-column>
          <b-table-column label="Folio" field="folio" v-slot="props">
            {{ props.row.folio }}
          </b-table-column>
          <b-table-column label="Nombre" field="patient" v-slot="props">
            {{ props.row.patient.full_name }}
          </b-table-column>
          <b-table-column label="Medico" field="medical" v-slot="props">
            {{ props.row.medical ? props.row.medical.name : ''}}
          </b-table-column>
          <b-table-column label="Empresa" field="company" v-slot="props">
            {{ props.row.company.name }}
          </b-table-column>
          <b-table-column label="Entrega" field="delivery_date" v-slot="props">
            {{ props.row.delivery_date_formatted }}
          </b-table-column>
          <b-table-column label="Elaboró" field="elaborate" v-slot="props">
            {{ props.row.elaborate }}
          </b-table-column>
          <b-table-column label="Toma" field="foreignWork" v-slot="props">
            {{ props.row.foreignWork? props.row.foreignWork.name: '' }}
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                      icon="box-open"
                      size="is-large">
                  </b-icon>
                </p>
                <p>No hay ordenes seleccionadas.</p>
              </div>
            </section>
          </template>
        </b-table>
        <div class="alfalab-table__buttons">
          <b-button type="is-primary" :disabled="page===1" @click="$emit('previousPage')">Anterior</b-button>
          <b-button type="is-primary" :disabled="!hasMore" @click="$emit('nextPage')">Siguiente</b-button>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary"  @click="$parent.close()">Cerrar</b-button>
    </footer>
  </div>

</template>

<script>
export default {
  name: "ShowFilterSearchOrderFull",
  props: {
    data: Array,
    page: Number,
    total: Number,
    hasMore: Boolean
  },
  data(){
    return {
      selected: {}
    }
  },
  methods: {
    async onSelectPatient(order){
      this.$loader.show();
      const params = {year: this.year, only_antigenos_sars: true, capture: true};
      await this.$http.get(`/orders/${order.order_new}`, {params}).then(({data}) => {
        this.$emit('select', data.data);
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      })
      this.$parent.close();
    }
  }
}
</script>

<style scoped>
.alfalab-table__buttons {
  display: flex;
  width: 100%;
  gap: 48px;
  padding: 24px;
  justify-content: flex-end;
}
</style>
