<template>
  <div class="modal-card" style="width: auto; height: 600px">
    <header class="modal-card-head">
      <p class="modal-card-title">Filtrar ordenes</p>
      <div style="text-align: center">
        <button
            type="button"
            class="delete"
            @click="$parent.close()"/>
      </div>
    </header>
    <section class="modal-card-body">

      <div class="column is-12">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <b-tooltip label="Buscar">
            <b-button icon-left="search" size="is-large" type="is-primary" @click="() => onSearch(true)"/>
          </b-tooltip>
          <b-tooltip label="Ver resultados de la busqueda en PDF"><b-button icon-left="file-pdf" size="is-large" type="is-primary" @click="onSearchAndShowPDF"/></b-tooltip>
          <b-tooltip label="Salir"><b-button icon-left="right-from-bracket" size="is-large" type="is-primary" @click="$parent.close()"/></b-tooltip>
        </div>
      </div>

      <div class="column is-12 columns is-multiline">
        <div class="column is-12 is-7-desktop is-8-widescreen is-6-fullhd has-background-white is-multiline">
          <div class="columns is-12 column is-multiline">
            <div class="column is-6">
              <b-field label="Procedencia"
                       label-position="on-border">
                <select-branch-office
                    :readonly="true"
                    :disabled="true"
                    v-model="branchOffice"
                    placeholder="Buscar procedencia"/>
              </b-field>

            </div>
            <div class="column is-6">
              <b-field label="Toma"
                       label-position="on-border">
                <b-autocomplete
                    v-model="toma"
                    placeholder=""
                    :open-on-focus="true"
                    :data="filteredDataObj"
                    field="name"
                    @select="option => {tomaSelected = (option ? option.id: '')}"
                    :clearable="true"
                >
                </b-autocomplete>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Estatus ordenes"
                       label-position="on-border">
                <b-select
                    v-model="status"
                    placeholder="Seleccionar">
                  <option value="">Todas</option>
                  <option value="3">Canceladas</option>
                  <option value="1">No canceladas</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Ubicación"
                       label-position="on-border">
                <b-select
                    v-model="location"
                    placeholder="Seleccionar">
                  <option value=""></option>
                  <option :value="location.id" v-for="location in locations" :key="location.id">{{location.name}}</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="column is-5-desktop is-4-widescreen is-6-fullhd has-background-white"
             style="border-left: 2px #dbdbdb solid">
          <div class="columns is-12 column is-multiline">
            <div class="column is-6">
              <b-field label="Fecha inicial"
                       label-position="on-border">
                <b-datepicker v-model="dateFirst"/>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Fecha final"
                       label-position="on-border">
                <b-datepicker v-model="dateLast"/>
              </b-field>
            </div>
            <div class="column">
              <b-field>
                <b-checkbox v-model="dateDelivery">Fecha entrega</b-checkbox>
              </b-field>
            </div>

            <div class="columns is-12 column is-multiline">
              <div class="column is-12">
                <b-field label="Estatus firma"
                         label-position="on-border">
                  <b-select
                      v-model="sign"
                      placeholder="Seleccionar">
                    <option value="1">Todas</option>
                    <option value="2">Firmadas</option>
                    <option value="3">Sin Firmar</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div class="columns is-12 column is-multiline">
          <div class="column is-6">
            <b-field label="Empresa"
                     label-position="on-border">
              <select-company
                  type="search"
                  label-position="on-border"
                  @select="selected => {company = selected.company}"
              />
            </b-field>
          </div>
          <div class="column is-6">
            <b-field label="Médico"
                     label-position="on-border">
            <select-medical
                type="search"
                label-position="on-border"
                @select="selected => {medical = selected.code.medical}"
            />
            </b-field>
          </div>
          <div class="column is-6">
            <b-field label="Ordenado por"
                     label-position="on-border">
              <b-select v-model="orderBy"
                  placeholder="Buscar">
                <option value="order">No.Orden</option>
                <option value="date-Order">Fecha + No.Orden</option>
                <option value="branchOffice-Local">Procedencia + No.Local</option>
                <option value="patientCode">Clave Paciente</option>
                <option value="patientName">Nombre de Paciente</option>
                <option value="company-patientName">Empresa + Nombre de Paciente</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field>
              <b-checkbox v-model="pendingSamples">Muestras pendientes</b-checkbox>
            </b-field>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary"  @click="$parent.close()">Cerrar</b-button>
    </footer>
    <b-modal :active.sync="showModal" full-screen>
      <show-filter-search-order-full
          @select="onSelect"
          @nextPage="onNextPage"
          @previousPage="onPreviousPage"
          :page="page"
          :total="total"
          :hasMore="hasMore"
          :data="orders"/>
    </b-modal>
    <b-modal v-model="showPDF">
      <iframe v-if="showPDF" class="lapi__iframe" :src="`${resultPdf}&rrrandom=${index}`">
      </iframe>
    </b-modal>
  </div>
</template>

<script>
import SelectCompany from "@/components/company/SelectCompany";
import SelectMedical from "@/components/medical/SelectMedical";
import SelectBranchOffice from "@/components/brach-office/SelectBranchOffice";
import ShowFilterSearchOrderFull from "@/components/order/ShowFilterSearchOrderFull";
import {mapState} from "vuex";
export default {
  name: 'FilterSearchOrderFull',
  components: {
    SelectCompany,
    SelectMedical,
    SelectBranchOffice,
    ShowFilterSearchOrderFull,
  },
  props: {
    searchParams: Object
  },
  data() {
      return {
        showModal:false,
        showPDF: false,
        branchOffice: '',
        toma: '',
        tomaSelected: null,
        status: '',
        location: '',
        company: '',
        medical: '',
        dateFirst: new Date(),
        dateLast: new Date(),
        dateDelivery: false,
        sign: '',
        signSelected: null,
        orderBy: '',
        pendingSamples: '',
        orders: [],
        page: 1,
        total: 0,
        hasMore: false,
        index : 0
      }
  },
  computed: {
    ...mapState({
      foreignWorkCatalog: state => state.main.foreignWorks,
    }),
    filteredDataObj() {
      return this.foreignWorkCatalog.filter(option => {
        return (
            option.name
                .toString()
                .toLowerCase()
                .indexOf(this.toma.toLowerCase()) >= 0
        )
      })
    },
    locations() {
      return this.$store.state.main.locations || [];
    },
    endpoint() {
      return process.env.VUE_APP_API_URL;
    },
    resultPdf() {
      const params = {
        ...this.searchParams,
        search_branch_office: this.branchOffice,
        search_status: this.status,
        search_location: this.location,
        search_company: this.company,
        search_medical: this.medical,
        search_date_first: this.toFormattedDate(this.dateFirst),
        search_date_last: this.toFormattedDate(this.dateLast),
        search_date_delivery: this.dateDelivery,
        search_sign_selected: this.sign,
        search_order_by: this.orderBy,
        search_pending_samples: this.pendingSamples,
        type_results: 'pdf'
      }
      if (this.tomaSelected) {
        params['search_toma_selected'] = this.tomaSelected;
      }
      const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      return this.endpoint + '/orders?' + queryString;
    },
    params() {
      return {
        ...this.searchParams,
        search_branch_office: this.branchOffice,
        search_toma_selected: this.tomaSelected,
        search_status: this.status,
        search_location: this.location,
        search_company: this.company,
        search_medical: this.medical,
        search_date_first: this.dateFirst,
        search_date_last: this.dateLast,
        search_date_delivery: this.dateDelivery,
        search_sign_selected: this.sign,
        search_order_by: this.orderBy,
        search_pending_samples: this.pendingSamples,
        perPage: 25,
        page: this.page
      }
    }
  },
  mounted() {
    this.branchOffice = this.$auth.user().active_branch_office
  },
  methods: {
    toFormattedDate(date){
      return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
    },
    onSearch(restart=false){
      if(restart){
        this.page = 1;
      }
      this.$loader.show();
      this.$http.get('/orders', {params: this.params}).then(({data}) => {
        this.orders = data.data;
        this.total = data.meta.total
        this.showModal = true;
        this.hasMore = !!data.meta.to;
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally( () => {
        this.$loader.hide();
      });
    },
    onSelect(data){
      this.$emit('select', data);
      this.$parent.close();
    },
    onSearchAndShowPDF() {
      this.showPDF = true;
      this.index++;
    },
    onNextPage(){
      this.page++;
      this.onSearch();
    },
    onPreviousPage() {
      if(this.page>1){
        this.page--;
        this.onSearch();
      }
    }
  }
}
</script>

<style scoped>
.lapi__iframe{
  width: 100%;
  height: 80vh;
}
</style>
