<template>
  <div>
    <b-field
        :label="label"
        :label-position="labelPosition">
      <b-autocomplete
          autocomplete="false"
          :disabled="disabled"
          :data="data"
          :placeholder="placeholder"
          :field="primaryField"
          :loading="isFetching"
          @typing="getAsyncData"
          v-model="localValue"
          @blur="onBlur"
          @select="onSelect"
          :required="! disabled"
          expanded>
      </b-autocomplete>
      <p class="control">
        <button class="button is-success tooltip"
                data-tooltip="Crear médico"
                :disabled="disabled"
                type="button"
                @click="createModalActive = true">
          <b-icon icon="plus-circle"></b-icon>
        </button>
      </p>
      <p class="control">
        <button class="button is-warning tooltip"
                data-tooltip="Editar médico"
                :disabled="!canEdit"
                @click="editModalActive = true">
          <b-icon icon="edit"></b-icon>
        </button>
      </p>
      <b-modal :active.sync="editModalActive"
               has-modal-card
               trap-focus
               aria-role="dialog"
               aria-modal>
        <form  @submit.prevent="onUpdateData">
          <form-modal-medical
              edit
              :data="selected"
              :errors="errors">
            <button
                type="submit"
                class="button is-warning"
                slot="action">
              Actualizar
            </button>
          </form-modal-medical>
        </form>
      </b-modal>
      <b-modal :active.sync="createModalActive"
               has-modal-card
               trap-focus
               :destroy-on-hide="false"
               aria-role="dialog"
               aria-modal>
        <form  @submit.prevent="onStoreData">
          <form-modal-medical
              :data="dataMedical"
              :errors="errors">
            <button
                type="submit"
                class="button is-success"
                slot="action">
              Crear
            </button>
          </form-modal-medical>
        </form>
      </b-modal>
    </b-field>
    <b-button
        v-if="false && showUpdateMedical"
        type="is-primary"
        @click="handleUpdate">Actualizar información del médico</b-button>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import FormModalMedical from "./FormModalMedical";

export default {
  name: "SelectMedical",
  components: {FormModalMedical},
  props: {
    label: {
      type: String,
      default() {
        return this.$t('medical.attributes.medical')
      }
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('medical.placeholders.medical')
      }
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    primaryField: {
      type: String,
      default: 'name'
    },
    value: {},
    medical: Object,
    labelPosition: String,
    disabled: Boolean,
    type: {
      type: String,
      default: 'order'
    },
    showUpdateMedical: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: [],
      dataMedical: {},
      selected: null,
      isFetching: false,
      apiUrl: 'medicals',
      params: {
        page: 1,
        perPage: 20,
        q: ''
      },
      localValue: null,
      editModalActive: false,
      createModalActive: false,
      errors: {}
    }
  },
  computed: {
    canEdit() {
      return !!this.selected;
    }
  },
  watch: {
    medical: {
      handler(val) {

        if (! val) {
          this.localValue = null
        } else  {
          this.localValue = val[this.primaryField];
        }
      },
      deep: true
    },
    value(val) {
      console.log(val);
    }
  },
  mounted() {
    if(this.medical){
      this.localValue = this.medical[this.primaryField];
    }
  },
  methods: {
    // You have to install and import debounce to use it,
    // it's not mandatory though.
    getAsyncData: debounce(function (name) {
      if (!name.length) {
        this.data = []
        return
      }
      this.isFetching = true
      this.params.q = name

      this.getAll(this.params)
          .then(({ data = [] }) => {
            this.data = []
            data.forEach((item) => this.data.push(item))
          })
          .catch((error) => {
            this.data = []
            throw error
          })
          .finally(() => {
            this.isFetching = false
          })
    }, 500),
    onSelect(data = null) {
      if(data === null){
        return
      }
      this.$emit('select', data);
      this.selected = data;
      this.$emit('update:medical', data);
      this.$emit('input', data[this.primaryKey]);
      switch (this.type) {
        case 'search':
          break;
        case 'order':
        default:
          this.$store.dispatch('order/setMedical', data);
          break;
      }
    },
    async onUpdateData() {
      this.$buefy.dialog.confirm({
        ...this.$t('medical.update'),
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          this.$loader.show();
          await this.updateData(this.selected.id, this.selected)
              .then((data) => {
                this.$loader.hide();
                this.$toast.success(data.message);
                this.$store.dispatch('order/setMedical', data.data);
                this.editModalActive = false;
              }, data => {
                this.$loader.hide();
                this.$toast.error(data.message);
              })
        }
      });
    },
    async onStoreData() {
      this.$buefy.dialog.confirm({
        ...this.$t('medical.create'),
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          this.$loader.show();
          await this.storeData(this.dataMedical)
              .then((data) => {
                this.$loader.hide();
                this.$toast.success(data.message);
                this.createModalActive = false;
                this.$store.dispatch('order/setMedical', data.data);
              }, data => {
                this.$loader.hide();
                this.$toast.error(data.message);
              })
        }
      });
    },
    onBlur() {
      setTimeout(() => {
        if (!this.selected) {
          this.localValue = '';
        }
      }, 600);
    },
    handleUpdate() {
      this.$emit('update-medical')
    }
  }
}
</script>

<style scoped>

</style>
