<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{ title }}
            </p>
        </header>
        <section class="modal-card-body">
            <form-medical :edit="edit"
                    class="columns is-multiline"
                          :data="data"></form-medical>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Cerrar</button>
            <slot name="action"></slot>
        </footer>
    </div>
</template>

<script>
    import FormMedical from "./FormMedical";
    export default {
        name: "FormModalMedical",
        components: {FormMedical},
        data() {
            return {
                localData: {},
                apiUrl: 'medicals'
            }
        },
        props: {
            data: Object,
            title: String,
            edit: Boolean
        },
        async mounted() {
            this.localData = this.data;
            if (!!this.edit) {
                this.$loader.show();
                await this.getData(this.data.id)
                    .then(({data}) => {
                        this.localData = data;
                    });
                this.$loader.hide();
            }
        }
    }
</script>

<style scoped>

</style>